

function Getsubscription(){
 
    const queryParameters = new URLSearchParams(window.location.search)
    const email = queryParameters.get("email")
    
    return(
        <>
<stripe-pricing-table pricing-table-id="prctbl_1NBE3HBRKOvwzTAfJiE2Xo6B"
publishable-key="pk_live_51N8UMYBRKOvwzTAfQGJsuzdglhxzPqqVx4LT1JMXXJpqChWnPyOcNkCEllEpwDG1CoqojfZJO0kfHgC88ujSidna00WKfAPKE6"
customer-email={email}>
    
</stripe-pricing-table>
        </>
    )
}


export default Getsubscription;