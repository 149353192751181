import logo from './logo.svg';
import './App.css';
import { Routes, Route } from "react-router-dom"
import Getsubscription from './components/Getsubscription';

function App() {
  return (
  <>
  <Routes>
    <Route path='/*' element={<Getsubscription/>} />
  </Routes>
  </>
  );
}

export default App;
